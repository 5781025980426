.root {
    width: 100%;
    background-color: var(--whitebase);
    padding: 10px 29px;
    padding-bottom: 14px;
    list-style: none;
    margin: 0;
    border-top: solid 8px var(--black-700);
    border-bottom: solid 8px var(--black-700);
}

.item {
    height: 28px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.button {
    font-family: "Roboto", sans-serif;
    height: 19px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
}

.button:active {
    background-color: var(--black-100);
}

.button span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-500);
}

.icon {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    padding: 3px;
    margin-right: 15px;
    fill: var(--whitebase);
    background-color: var(--success-500);
}

.circleIcon {
    width: 13px;
    height: 13px;
    margin-right: 15px;
    fill: var(--success-500);
}

.iconMinus {
    width: 13px;
    height: 13px;
    margin-right: 15px;
    fill: var(--black-700);
}

.selected {
    background-color: #e4dfef;
}

@media (min-width: 660px) {
    .root {
        position: absolute;
        top: 27px;
        left: 16px;
        z-index: 10;
        min-width: 130px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px var(--black-300);
        background-color: var(--whitebase);
        padding: 0;
        padding-bottom: 2px;
    }

    .item {
        padding: 0;
        border-bottom: solid 0.5px var(--black-100);
        height: 32px;
    }

    .button {
        padding: 0;
        width: auto;
        height: 30px;
        width: 100%;
        align-items: center;
        padding-left: 13px;
        padding-top: 7px;
    }

    .button:active {
        background-color: var(--black-100);
    }

    .button span {
        display: block;
        margin-left: 9px;
        max-width: 95px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon {
        margin: 0;
        width: 10px;
        height: 10px;
        background-color: transparent;
        fill: var(--success-500);
        padding: 0;
    }

    .iconMinus {
        margin: 0;
        width: 14px;
        height: 14px;
        background-color: transparent;
        fill: var(--black-700);
        padding: 0;
        margin-right: -3px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .root {
        position: relative;
        top: 0;
        left: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
        min-width: 272px;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .item {
        width: 100%;
        height: auto;
        margin-bottom: -0.5px;
        margin-top: -0.5px;
        border: none;
    }

    .button {
        width: 100%;
        height: 41px;
        border: solid 0.5px var(--black-300);
        display: block;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 8px 15px;
        cursor: pointer;
    }

    .button span {
        font-size: 18px;
        font-weight: 300;
        max-width: 210px;
        margin-left: 14px;
    }

    .icon {
        width: 11px;
        height: 11px;
    }

    .iconMinus {
        height: 17px;
        width: 17px;
        margin-right: -6px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
