.root {
    width: 100%;
    background-color: var(--black-700);
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.sidebar {
    width: 100%;
    background-color: var(--black-100);
}

.buttonWrapper {
    width: 100%;
    max-width: 1377px;
    min-height: 26px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {
    width: 100%;
    max-width: 1377px;
    margin: 0 auto;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
}

.h1 {
    margin: 0;
    padding: 0;
    display: flex;
}

.logo {
    width: 200px;
    height: 34px;
}

.logoText {
    width: 62px;
    height: 33px;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    text-align: justify;
    color: #ffffff;
    margin-left: 9px;
}

.signatureIcon {
    margin-left: auto;
    width: 20px;
    height: 19px;
}

.text {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--whitebase);
    margin-left: 4px;
}

/* --tablet-widths */
@media (min-width: 660px) {
    .buttonWrapper {
        min-height: 39px;
        max-width: 904px;
    }
    .wrapper {
        height: 118px;
        max-width: 904px;
        padding-left: 24px;
        padding-right: 22px;
    }

    .logo {
        width: 200px;
        height: 61px;
    }

    .logoText {
        width: 121px;
        height: 69px;
        font-size: 18px;
        margin-left: 19px;
        letter-spacing: 1.8px;
        line-height: 1.3;
        margin-top: -4px;
    }

    .signatureIcon {
        width: 41px;
        height: 36px;
        margin-top: 6px;
    }

    .text {
        font-size: 20px;
        font-weight: bold;
        margin-left: 18px;
        padding-top: 6px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .buttonWrapper {
        max-width: 1377px;
    }
    .wrapper {
        max-width: 1337px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
