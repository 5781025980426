.root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.input {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.ellipse {
    display: block;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

.ellipse::before {
    position: absolute;
    box-sizing: border-box;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 2px var(--black-100);
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}

.input:checked ~ .ellipse::before {
    border-color: var(--primary-400-base);
}

.input:checked ~ .ellipse::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    background-color: var(--primary-400-base);
}

.input:disabled ~ .ellipse {
    cursor: auto;
}

.input:disabled ~ .ellipse::after {
    background-color: var(--black-100);
}

.input:disabled ~ .ellipse::before {
    border-color: var(--black-100);
}

.input:focus ~ .ellipse {
    box-shadow: 0 0 0 2px var(--primary-500);
}

.text {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    padding: 0;
    margin: 0;
    margin-right: 9px;
    margin-left: 9px;
    color: var(--black-base);
}

.customInputs {
    width: 18px;
    height: 18px;
}

@media (min-width: 660px) {
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .text {
        margin-right: 2px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
