.root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
}

.button {
    padding: 0 20px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--whitebase);
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0;
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding-right: 20px;
    text-transform: uppercase;
}

.icon {
    width: 14px;
    height: 14px;
    margin-right: 7px;
    fill: var(--success-500);
}

.iconMinus {
    margin-right: 7px;
    fill: var(--whitebase);
}

.triangleIcon {
    width: 14px;
    height: 8px;
    transform: rotate(-90deg);
    margin-left: auto;
    fill: var(--whitebase);
}

.triangleIconRotate {
    transform: rotate(0);
}

@media (min-width: 660px) {
    .root {
        margin-right: 22px;
        position: relative;
    }

    .root::after {
        position: absolute;
        content: "/";
        right: -11px;
        color: var(--black-700);
        font-size: 14px;
        line-height: 14px;
        top: calc(50% - 7px);
    }

    .root:last-child::after {
        display: none;
    }

    .icon {
        width: 14px;
        height: 14px;
        border-radius: none;
        fill: var(--success-500);
        background-color: transparent;
        padding: 0;
    }

    .iconMinus {
        fill: var(--black-700);
        margin-right: 5px;
    }

    .button {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--black-700);
        padding: 0;
        text-transform: capitalize;
    }

    .button:disabled {
        background-color: var(--stage-background-disbled);
        /* text-decoration: line-through; */
        color: var(--stage-color-disbled);
    }

    .button span {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
    }

    .triangleIcon {
        fill: var(--black-700);
        transform: rotate(0);
        width: 9px;
        margin-left: 3px;
    }

    .triangleIconRotate {
        transform: rotate(0);
    }

    .buttonChecked span {
        color: var(--success-500);
        font-weight: bold;
    }

    .buttonChecked .triangleIcon {
        fill: var(--success-500);
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .root {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
    }

    .root::after {
        display: none;
    }

    .iconMinus {
        fill: var(--whitebase);
        margin: 0;
        width: 13px;
        height: 13px;
    }

    .button {
        background-color: var(--black-700);
        width: 100%;
        font-family: inherit;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--whitebase);
        height: 40px;
        padding-left: 7px;
    }

    .button:disabled {
        background-color: var(--stage-background-disbled);
        /* text-decoration: line-through; */
        color: var(--stage-color-disbled);
    }

    .button span {
        margin-left: 13px;
        max-width: 205px;
    }

    .triangleIcon {
        order: -1;
        fill: var(--whitebase);
        width: 14px;
        height: 14px;
        transform: rotate(-90deg);
        margin-right: 10px;
    }

    .triangleIconRotate {
        transform: rotate(0);
    }

    .icon {
        width: 13px;
        height: 13px;
        fill: var(--whitebase);
        margin: 0;
    }

    .buttonChecked {
        background-color: var(--success-500);
    }

    .buttonChecked span {
        color: var(--whitebase);
        font-weight: bold;
    }

    .buttonChecked .triangleIcon {
        fill: var(--whitebase);
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
