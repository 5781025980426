.iframe {
    position: absolute;
    height: calc(100% - 56px);
    width: 100%;
    border: solid 5px var(--black-100);
    border-left-width: 1px;
    border-right-width: 1px;
}
.root {
    min-height: 900px;
}

.panel {
    width: 100%;
    height: 20px;
    background-color: #e3e3e3;
}

.h2 {
    margin: 20px;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
}

@media (min-width: 660px) {
    .h2 {
        font-size: 36px;
        line-height: 0.67;
        margin: 20px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .iframe {
        height: calc(100% - 110px);
    }

    .h2 {
        /* margin-top: 59px; */
        margin: 0 20px 80px 20px;
        font-size: 36px;
        line-height: 0.67;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .h2 {
        margin: 20px;
    }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .root {
        min-height: auto;
        height: 420px;
        overflow-y: scroll;
    }

    .root::-webkit-scrollbar {
        width: 15px;
        height: 15px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
    }
    .root::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #c3c3c3;
        border: 2px solid #eee;
    }

    .root::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
}
